
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
export default defineComponent({
  name: "edit-details-rfq-offer-modal",  
  directives: {mask},
  props: {
    data: {
        type: Object
    }
  },
  components: {    
    },    
  methods :{
  },

  setup(props) {    

    const store = useStore();
    const router = useRouter();    
    const loadingPan = ref<boolean>(false);
    const loadingPin = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const EditRfqOfferModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    

    const incoterm = ref([]);
    const payment_term = ref([]);
    const delivery_term = ref([]);
    const product_charge:any = ref([]);
    const supplier_companies = ref([]);
    const manufacturer_data = ref([]);
    const branch_list = ref([]);
    const plant_data = ref([]);
    const brand_list = ref([]);
    const product_description: any = ref({});
    
    const offerDetailsData = ref({});
    const isEditModeOn = ref<boolean>(false)

    const formData = ref({
      supplier_company: '',
      supplier_company_name:'',
      supplier_company_branch: '',
      manufacturers_select: '',
      mfg_plant:'',
      brand: '',
      offer_validity: '',
      payment_term:'',
      incoterm:'',
      expected_delivery_period:'',
      remark:'',
      required_qty: '',
      offered_qty:'',
      rate:'',
      total_rate: ''
    }) as any

    const getPaymentTerms = async() => {
      await store
        .dispatch(Actions.CUST_GET_PAYMENT_TERM)
        .then(({ data }) => {
          debugger;
          payment_term.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const setIncotermData = async () => {
        loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRODUCT_INCOTERM)
          .then(({ data }) => {
          incoterm.value = data;
          console.log(incoterm.value)
          loading.value = false;
        })
        .catch(({ e }) => {
          console.log(e);        
        });
      } catch (e) {
        console.log(e);
      }
    }

    const setDeliveryData = async () => {
        try {
          await store.dispatch(Actions.CUST_PRODUCT_DELIVERY_TERM)
          .then(({ data }) => {
          delivery_term.value = data;
        })
        .catch(({ e }) => {
          console.log(e);        
        });
      } catch (e) {
        console.log(e);
      }
    }

    const getProductDescription = async() => {
      let params = {pq_detail_id : props.data?.pq_detail_id};
      await store
        .dispatch(ActionsFi.CUST_GET_PRODUCT_DESCRIPTION,params)
        .then(({ data }) => {
          product_description.value = data[0];
          formData.value['required_qty'] = product_description.value['pq_quantity'];
          getCompanyList();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }


    const getSupplierCompnyList = async() => {
      let params = {search_term : ""}
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST,params)
        .then(({ data }) => {
          supplier_companies.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getSupplierCompnyBranchList = async() => {
      let params = {
         company_branch_id: 0,
         company_id: formData.value.supplier_company,
         page : 1,
        records_per_page : 10
      }

      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST,params)
        .then(({ data }) => {
          debugger;
          branch_list.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getCompanyList = async () => {
      let values = {
        category_id: product_description?.value?.category_id,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_MANUFACTURER_LIST_DATA, values)
        .then(({ data }) => {
          debugger;
          manufacturer_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const  getPlants = async() => {
      let values = {
        company_id: formData?.value?.manufacturers_select,
        company_branch_id: 0,
        page: 1,
        records_per_page: 50,
      };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_PLANT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          plant_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

      const getBrands = async() => {
      let values = {
        company_id: formData?.value?.manufacturers_select,
        company_branch_id: 0,
        page: 1,
        records_per_page: 50,
      };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_BRAND_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          brand_list.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getQuotationOfferDetails = async() => {
        let params = { quotation_offer_id : props.data?.quotation_offer_id}
        try{
          await store.dispatch(ActionsFi.CUST_GET_PURCHASE_QUOTATION_OFFER_DETAILS, params)
          .then(({ data }) => {
            if(Object.keys(data)?.length){
                offerDetailsData.value = data;
                product_charge.value = data?.product_other_charges_offer;
                formData.value.supplier_company = data?.supplier_company_id;
                formData.value.supplier_company_name = data?.supplier_company_name
                formData.value.supplier_company_branch = data?.supplier_company_branch_id;
                formData.value.manufacturers_select = data?.mfg_company_id == 0 ? '' : data?.mfg_company_id;
                formData.value.mfg_plant = data?.mfg_plant_id ? data?.mfg_plant_id : '';               
                formData.value.offer_validity = data?.offer_validity_days;
                formData.value.payment_term = data?.payment_term_id;
                formData.value.incoterm = data?.incoterm_id;
                formData.value.expected_delivery_period = data?.delivery_term_id                                              
                formData.value.remark = data?.offer_remark;
                formData.value.offered_qty = data?.offered_quantity;
                formData.value.rate = data?.product_rate_offer.toString();
                formData.value.total_rate = data?.total_rate_offer
            }
            getPlants();
            getBrands();
          })
          .catch (({ e }) => {
            console.log(e)
          });
        }
        catch (e){
           console.log(e)
        }
    }

    const calculateTotal = async(editedRow:any = null, chargeName) => {
        if(editedRow === null && chargeName == 'offered_qty'){
          if (!/^\d*\.?\d*$/.test(formData.value[chargeName]) || formData.value[chargeName] == 0){
            formData.value[chargeName] = formData.value[chargeName].slice(0, -1);
            return
          } 
        }else if(editedRow === null && chargeName == 'rate'){
          if (!/^\d*\.?\d*$/.test(formData.value[chargeName]) || formData.value[chargeName] == 0){
            formData.value[chargeName] = formData.value[chargeName].slice(0, -1);
            return
          }else{
            calculate();
          }
        }else if(editedRow && chargeName){
          if (!/^\d*\.?\d*$/.test(editedRow.value)){
            editedRow.value = editedRow.value.slice(0, -1);
            return
          }else{
            calculate();
          }
        }
    }
    
    const calculate = () => {
       let total = 0 ;
        let rate = Number.isNaN(parseFloat(formData.value['rate'].trim())) ? 0 
                    : parseFloat(formData.value['rate'].trim());
        product_charge.value.map((item, index) => {
             let input = parseFloat(item.value.trim())
             let inputValue = Number.isNaN(input) ? 0 : input;
             total = total + inputValue;
        })
        formData.value['total_rate'] = parseFloat((total+rate).toString()).toFixed(4)
    }

    const setEditMod = () => {
      isEditModeOn.value = !isEditModeOn.value
    }

    const clearForm = () => {
      formData.value = {
        supplier_company: '',
        supplier_company_name: '',
        supplier_company_branch: '',
        manufacturers_select: '',
        mfg_plant:'',
        brand: '',
        offer_validity: '',
        payment_term:'',
        incoterm:'',
        expected_delivery_period:'',
        remark:'',
        required_qty: '',
        offered_qty:'',
        rate:'',
        total_rate: ''
      }
      formRef?.value?.resetFields();
      formRef?.value?.clearValidate();   
      hideModal(EditRfqOfferModalRef.value);
    }

    onMounted( async () => {       
      EditRfqOfferModalRef.value?.addEventListener(
        "show.bs.modal",
        async function (event) {
          await setIncotermData();
          await getPaymentTerms();
          await setDeliveryData();
          await getProductDescription();
          await getSupplierCompnyList();
          await getPlants();
          await getBrands();
        }
      );
    });  

    onUpdated(async () => {
      await getQuotationOfferDetails();
      isEditModeOn.value = false;
      await getSupplierCompnyBranchList();
    })

    const rules = ref({
      supplier_company: [
        {
          required: true,
          message: "Company name is required",
          trigger: "change",
        },
      ],
      supplier_company_branch: [
        {
          required: true,
          message: "Company branch name is required",
          trigger: "change",
        },
      ],
      // mfg_plant: [
      //   {
      //     required: true,
      //     message: "Plant is required",
      //     trigger: "change",
      //   },
      // ],
      offer_validity: [
        {
          required: true,
          message: "Offer validity is required",
          trigger: "change",
        },
      ],
      payment_term: [
        {
          required: true,
          message: "Payment term is required",
          trigger: "change",
        },
      ],
      incoterm: [
        {
          required: true,
          message: "Incoterm is required",
          trigger: "change",
        },
      ],
      expected_delivery_period: [
        {
          required: true,
          message: "Expected delivery period required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async () => {       
      let user = JSON.parse(JwtService.getToken());
      let productOtherChargeOffer = product_charge.value.map((data) => {
            const {charge_id, value} = data;
            return {charge_id, value}
     });

     if((!formData?.value?.offered_qty) || (!formData?.value?.rate)){
      Swal.fire({
                text: "Please enter valid offerd qty and rate.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              })
              loading.value = false;
              return;
     }

     const requestData = {
      user_id: user.user_id,
      quotation_offer_id: offerDetailsData.value['quotation_offer_id'],
      pq_detail_id: offerDetailsData.value['pq_detail_id'],
      supplier_company_id: formData.value['supplier_company'],
      supplier_company_branch_id: formData.value['supplier_company_branch'],
      mfg_company_id: formData.value['manufacturers_select']== "" ? 0 : formData.value['manufacturers_select'],
      mfg_plant_id: formData.value['mfg_plant'],
      brand_id : formData.value['brand'],
      offered_quantity: formData.value['offered_qty'],
      product_rate_offer: formData.value['rate'],
      product_other_charges_offer: productOtherChargeOffer,
      total_rate_offer: formData.value['total_rate'],
      required_quantity: product_description.value['pq_quantity'],
      offer_validity_days: formData.value['offer_validity'],
      payment_term_id: formData.value['payment_term'],
      incoterm_id: formData.value['incoterm'],
      delivery_term_id: formData.value['expected_delivery_period'],
      offer_remark: formData.value['remark'],
     }

      await store.dispatch(ActionsFi.CUST_CREATE_UPDATE_PURCHASE_QUOTATION_OFFER, requestData)
      .then(({ data }) => {
        if (data){   
            setTimeout(() => {
              loading.value = false;
              hideModal(EditRfqOfferModalRef.value);
              Swal.fire({
                text: "Offer has been updated successfully .",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.go(0);            
              });
            }, 2000)          

          } else {
            loading.value = false;
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      })
      .catch(({ response }) => {
        console.log(response);        
      });
    }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }
      formRef.value.validate (async (valid) => {
        if (valid) {
          await setCompanyData();
        } else {

          loading.value = false;
          return false;
        }
      });  
    }
    
    return {
      loadingPin,
      loadingPan,
      formData,
      rules,
      payment_term,
      incoterm,
      delivery_term,
      product_charge,
      product_description,
      supplier_companies,
      manufacturer_data,
      branch_list,
      getPlants,
      getBrands,
      plant_data,
      brand_list,
      calculateTotal,
      submit,
      formRef,
      loading,
      EditRfqOfferModalRef,
      clearForm,
      isEditModeOn,
      setEditMod
    };
  },
});
