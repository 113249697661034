
import { defineComponent, onMounted, ref } from "vue";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import RfqOfferModal from "@/components/modals/forms/AddRfqOffer.vue";
import RfqOfferDetails from "@/components/modals/forms/RfqOfferDetailsModal.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  props: {
    id: { required: true },
  },

  name: "list_offers_rfq",
  components: {
    RfqOfferModal,
    RfqOfferDetails
  },

  setup(props) {
    const checkedCompany = ref([]);
    const offerListTable = ref([]);
    const tableData = ref([]);
    const loadingData = ref<boolean>(true);
    const quotation_offer_id = ref<number>();
    const pqId = ref<number>();
    const store = useStore();
    const quotationDetailId = ref(`Quotation Detail Id : ${props.id}`)

    getOffersList();

    const showOfferDetailsModal = (id) => {
        quotation_offer_id.value = id
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Request Quotation Offers", []);
    });


    async function getOffersList() {
      loadingData.value = true;
      let params = { pq_detail_id: props?.id };
      await store
        .dispatch(ActionsFi.CUST_GET_QUOTATION_REQUEST_OFFER_LIST, params)
        .then(({ data }) => {
          debugger;
          pqId.value = data[0]?.pq_id;
          offerListTable.value = data;
          loadingData.value = false;
          console.log("offerData", data);
        })
        .catch(({ response }) => {
          console.log(response);
          loadingData.value = false;
        });
    }

    return {
      checkedCompany,
      tableData,
      pqId,
      offerListTable,
      loadingData,
      quotation_offer_id,
      quotationDetailId,
      showOfferDetailsModal
    };
  },
});
